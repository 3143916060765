<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Reporte de clientes' }]" />
    <v-card>      
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Reporte de clientes que más compras han realizado</v-toolbar-title>
        <v-spacer />
        <v-btn @click="Download" color="primary">
        <svg style="width:24px;height:24px; margin-right: 8px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
        </svg>
          Exportar a Excel
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm6>
            <v-text-field v-model="search" box label="Buscar" clearable hide-details />
          </v-flex>
          <v-flex sm6>
            <v-btn :loading="loading4" :disabled="loading4" color="primary" @click="searchData" large>
            <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
              Buscar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn color="error" @click="clearSearch" large>
             <v-icon dark style="margin-right:10px;">
                  delete
                </v-icon>
              Limpiar
            </v-btn>
          </v-flex>
          <v-flex sm12>
            <v-data-table
              :headers="[
                { text: 'ID' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Apellido', value: 'apellidos' },
                { text: 'Teléfono', value: 'telefono' },
                { text: 'Email', value: 'email' },
                { text: 'Dirección', value: 'email' },
                { text: 'Ciudad', value: 'ciudad.nombre' },
                { text: 'Cantidad', value: 'cantidad' }
              ]"
              :items="clientes"
              :loading="loadingReporteClientes"
              hide-actions
              :pagination.sync="pagination"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.id }}
                </td>
                <td class="px-3">
                  {{ props.item.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.apellidos }}
                </td>
                <td class="px-3">
                  {{ props.item.telefono }}
                </td>
                <td class="px-3">
                  {{ props.item.email }}
                </td>
                <td class="px-3">
                  {{ props.item.direccion.direccion }}
                </td>
                <td class="px-3">
                  {{ props.item.ciudad.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.cantidad }}
                </td>
              </tr>
            </v-data-table>

            <div class="text-xs-center pt-2 md4 lg4">
              <v-pagination
                @input="paginationChangeHandler"
                v-model="pagination.pag1"
                :length="pages"
                :total-visible="7"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import XLSX from "xlsx";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;

export default {
  metaInfo() {
    return { title: "Listado de clientes más compradores" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/paises/ModalDeletePais")
  },
  data() {
    return {
      desde: "",
      hacia: "",
      loading4: false,
      search: "",
      pages: "",
      pagination: { totalItems: 0, rowsPerPage: 15, pag1: 1 },
      clientes: []
    };
  },

  computed: {
    ...mapState({
      reporteClientes: state => state.reportes.reporteClientes,
      loadingReporteClientes: state => state.reportes.loadingReporteClientes
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions({
      getClientes: "reportes/getClientes"
    }),
    async Download() {
      const res = await axios.get(`${HOST}/reportes/clientes/carros/exportar`);
      const url = res.data.data.file_path;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "carros.xls");
      document.body.appendChild(link);
      link.click();
    },
    clearSearch() {
      this.search = "";
      this.getData();
    },
    exportFile() {
      const datos = XLSX.utils.json_to_sheet(this.reporteClientes, { cellDates: true });
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, datos, "datos");
      XLSX.writeFile(wb, "Reporte_Clientes.xlsx");
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.pag1 = pageNumber;
      this.getData();
    },
    async searchData() {
      this.loading4 = true;
      await this.getData();
      this.loading4 = false;
    },
    getData() {
      let url = `${HOST}/reportes/clientes/carros?search=${this.search}&page=${this.pagination.pag1}`;
      axios.get(url).then(response => {
        this.clientes = response.data.data;
        this.loading = false;
        this.pagination.totalItems = response.data.meta.total;
        this.pagination.rowsPerPage = response.data.meta.per_page;
        this.pagination.pag1 = response.data.meta.current_page;
        this.pages = response.data.meta.last_page;
      });
    }
  }
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
